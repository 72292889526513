<template>
  <div>
    <Dialog
    v-model:visible="showIt"
    style="width:400px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">New Genre</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid">

          <div class="field">
            <label for="name" :class="{'p-error':nameError}">{{ $t('forms.name') }}</label>
            <InputText id="name" type="text"
              v-model="name"
              :class="{'p-invalid': nameError}"
              :disabled="isLoading"
            />
            <small v-if="nameError" class="p-error">{{nameError}}</small>
          </div>

        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text px-button-cancel" @click="showIt = false"/>
          <Button :label="$t('actions.create')" icon="pi pi-check" class="px-button-success" @click="create"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import Message from "primevue/message"

import { mapGetters } from 'vuex'
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"


export default {
  setup: () => ({ v$: useVuelidate() }),
  props: ['modelValue'],

  components: {
    InputText, Message, 
  },

  data: () => ({
    name: '',

    showIt: false,
    isLoading: false,
    errorCaught: ''
  }),

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.name = ''
    },

    create () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.resource.createGenre({
        name: this.name,
      })
      .then(res => {
        this.$router.push(`/resources/genres/${res.id}`)
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  },

  computed: {
    ...mapGetters('resource', ['languages', 'languageById', 'defaultInitLanguage']),
    nameError () {
      if (!this.v$.name.$dirty) return ''
      if (this.v$.name.required.$invalid) return 'Name is required.'
      return ''
    },
  },

  validations: {
    name: { required },
  },

  watch: {
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>