<template>
  <div>
    <slot name="header" 
      :hasUpdate="hasUpdate" 
      :hasError="hasError" 
      :hasLinksError="hasLinksError" 
      :saveFlowBuild="saveFlowBuild" />
    <TabView>
      <TabPanel>
        <template #header>
          <span :class="{'p-error': hasError}">
            <i v-if="hasError" class="mr-1 pi pi-exclamation-triangle"></i>
            {{ $t('tabs.generalInformation') }}
          </span>
        </template>
        <slot name="generalTab"
          :editorKey="editorKey" />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>

// import { mapActions, mapGetters } from 'vuex'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import { useFlowBuilder } from '@/compositions/useFlowEditor'

export default {
  name: 'EditingCore',
  props: {
    onCreate: Function,
    doc: Object,
    flow: String,
    editorMain: String
  },
  components: {
    TabView, TabPanel
  },
  setup (props, { emit }) {
    return {
      ...useFlowBuilder(props.flow, props.doc, {
        editorMain: props.editorMain,
        onCreate: props.onCreate,
        onFieldChange: (field, value) => {
          emit('field-change', {field, value})
        },
        onUpdateChange: ({hasUpdate, at}) => {
          emit('has-update', {hasUpdate, at})
        }
      })
    }
  },
}
</script>
