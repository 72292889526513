<template>
  <div>
    <div v-if="doc">

      <EditingCore :onCreate="onCreate" :doc="doc" :flow="flow" @has-update="onUpdateChanged">
        <template #header="{ hasUpdate, hasError, saveFlowBuild }">

          <div class="flex justify-content-end mb-2">
            <div>
              <Button
              :label="$t('actions.submit')"
              icon="pi pi-check"
              iconPos="right"
              :disabled="!hasUpdate || isSaving || hasError"
              :loading="isSaving"
              @click="save(saveFlowBuild('*'))"
              />
            </div>
          </div>

        </template>

        <template #generalTab="{ editorKey }">
          <GenreEditor :editorKey="editorKey" />
        </template>

      </EditingCore>

    </div>
    <div v-else>
      <div class="flex justify-content-center">
        <p class="text-4xl font-light text-center">
          Loading genre ...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Log from '@/services/logger'
import EditingCore from '@/pages/resources/components/EditingCore.vue'
import GenreEditor from './components/GenreEditor.vue'

import { useFlowCruder, useFlowReset } from '@/compositions'
import { LOGIN_PAGE } from '@/router'

const onCreate = (resource) => {
  return {
    name: resource.name
  }
}

const KFlow = "resource:genre"

export default {
  components: {
    EditingCore,
    GenreEditor
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasUpdate) {
      return next ()
    } else if (to.name === LOGIN_PAGE && to.query.redirect) {
      return next()
    }
    this.$confirm.require({
      header: 'You have unsaved changes!',
      message: 'Do you really want to leave?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => { next() },
      reject: () => { next(false) }
    })
  },
  setup () {
    const isReloading = ref(false)
    const hasUpdate = ref(false)
    const lastUpdate = ref(new Date(0))
    const doc = ref(null)

    return {
      hasUpdate,
      lastUpdate,
      isReloading,
      flow: KFlow,
      onCreate,
      doc,
      ...useFlowCruder()
    }
  },
  methods: {

    async save (req) {
      Log('@@@ SAVE @@@')
      Log('Req: ', JSON.parse(JSON.stringify(req)))
      try {
        const { redirect } = await this.flowSave(req)
        this.$toast.add({severity: 'success', detail: 'Saved with success', summary: req.doc.name, life: 4000})
        if (redirect) {
          this.$router.push(`/resources/genres/${redirect}`)
          return
        }

        this.isReloading = true
        useFlowReset()
        // Force EditingCore setup by removing dom
        this.doc = null
        const { data } = await this.flowGet(KFlow, this.$route.params.id)
        this.doc = data
        this.isReloading = false
        // Reset hasUpdate flag
        this.onUpdateChanged({
          hasUpdate: false,
          at: new Date(),
        })

      } catch (e) {
        Log.Error(e)
        this.$toast.add({severity: 'error', summary: 'Failed to save genre', detail: e.error || e.message, life: 4000})
      }
    },
    onUpdateChanged ({hasUpdate, at}) {
      this.hasUpdate = hasUpdate
      this.lastUpdate = at
    }
  },
  async mounted () {
    useFlowReset()

    const docId = this.$route.params.id
    let title = `Genre ${docId}`
    // let owner = '-- No Owner--'

    try {
      const { data } = await this.flowGet(KFlow, docId)
      this.doc = data
      title = this.doc.name
    } catch (err) {
      this.$toast.add({severity: 'error', summary: 'Failed to get genre', detail: err.toString(), life: 4000})
      setTimeout(() => this.$router.push('/resources/genres'), 4000)
    }

    this.$breadcrumb.setCurrent(title, [
      {label: 'Resources', disabled: true},
      {label: 'Genres', disabled: false, to: '/resources/genres'},
      {label: title, disabled: true}
    ], { caption: '', badge: docId === 'new' ? 'Draft' : ''})

  }
}
</script>
