<template>
  <div>
    <DataTable
      v-model:filters="lazyParams.filters"
      :sortField="lazyParams.sortField"
      :sortOrder="lazyParams.sortOrder"
      ref="dt"
      class="p-datatable-sm"
      stripedRows
      showGridlines
      filterDisplay="menu"
      responsiveLayout="scroll"
      rowHover
      :value="list"
      lazy
      paginator
      :rows="20"
      :totalRecords="totalRecords"
      :loading="loading"
      :rowsPerPageOptions="[10, 20, 50]"
      @row-click="edit($event.data)"
      @page="onLazyParams($event)"
      @sort="onLazyParams($event)"
      @filter="onLazyParams($event)">
      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="lazyParams.filters['search'].value" :placeholder="$t('buttons.search')" @keydown.enter="getList()" />
          </span>
          <Button type="button" icon="pi pi-plus" label="New artist" class="p-button-outlined" @click="create()"/>
        </div>
      </template>

      <template #empty>
        No artist found.
      </template>

      <template #loading>
        Loading artists. Please wait.
      </template>

      <Column field="name" :header="$t('forms.title')" :sortable="true"></Column>

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('forms.actions') }}</div>
          </div>
        </template>

        <template #body="slotProps">
          <div class="flex justify-content-end w-full">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(slotProps.data)" />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
              :loading="slotProps.data.__isRemoving"
              @click="confirmRemove(slotProps.data)"
            />
          </div>
        </template>
      </Column>

    </DataTable>

    <ArtistNew v-model="ui.showCreateArtist" />
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Log from '@/services/logger'

import ArtistNew from './components/ArtistNew.vue'

const KFlow = "resource:artist"

export default {
  components: {
    DataTable,
    Column,
    InputText,
    ArtistNew
  },
  data: () => ({
    loading: false,
    totalRecords: 0,
    list: null,
    lazyParams: {
      sortField: 'name',
      sortOrder: 1,
      filters: {
        'search': { value: '', matchMode: 'contains' }
      }
    },
    ui: {
      showCreateArtist: false
    }
  }),
  methods: {
    onLazyParams (e) {
      if (e) {
        this.lazyParams = e
      }
      this.getList()
    },
    edit (e) {
      this.$router.push(`/resources/artists/${e.id}`)
    },
    create () {
      this.ui.showCreateArtist = true
    },
    confirmRemove (data) {
      this.$confirm.require({
        message: `Are you sure you want to delete this artist?`,
        header: `Delete ${data.name}`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.remove(data)
        },
        reject: () => {}
      })
    },
    async remove (data) {
      data.__isRemoving = true
      this.$pxstream.deleteOne(KFlow, data.id)
        .then(() => {
          this.$toast.add({severity: 'success', detail: 'Removed with success', summary: data.name, life: 4000})
          data.__isRemoving = false
          this.getList()
        })
        .catch((err) => {
          data.__isRemoving = false
          this.$toast.add({severity: 'error', summary: 'Failed to remove media '+data.name, detail: err.toString(), life: 4000})
        })
    },
    async getList () {
      Log(this.lazyParams)
      const params = JSON.parse(JSON.stringify(this.lazyParams))

      Log(params)

      this.loading = true
      const { data: list, total} = await this.$pxstream.resource.getArtists(params)
      this.totalRecords = total
      this.list = list
      this.loading = false
    }
  },
  async mounted () {
    this.$breadcrumb.setCurrent('Artists', [
      {label: 'Resources', disabled: true},
      {label: 'Artists', disabled: true}
    ])

    await this.getList()
  }
}
</script>

<style>

</style>