<template>
  <div>
    <DataTable
      v-model:filters="lazyParams.filters"
      :sortField="lazyParams.sortField"
      :sortOrder="lazyParams.sortOrder"
      :totalRecords="totalRecords"
      @row-click="edit($event.data)"
      @page="onLazyParams($event)"
      @sort="onLazyParams($event)"
      @filter="onLazyParams($event)"
      :loading="loading"
      :value="list"
      v-bind="defaultTableProperties"
      >

      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="lazyParams.filters['search'].value" :placeholder="$t('buttons.search')" @keydown.enter="getList()" />
          </span>
          <Button type="button" icon="pi pi-plus" :label="$t('buttons.newGenre')" class="p-button-outlined" @click="create()"/>
        </div>
      </template>

      <template #empty>
        No genre found.
      </template>

      <template #loading>
        Loading genres. Please wait.
      </template>

      <Column field="name" :header="$t('forms.name')" sortable />
      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('forms.actions') }}'</div>
          </div>
        </template>

        <template #body="slotProps">
          <div class="flex justify-content-end w-full">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(slotProps.data)" />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
              :loading="slotProps.data.__isRemoving"
              @click="confirmRemove(slotProps.data)"
            />
          </div>
        </template>
      </Column>

    </DataTable>

    <GenreNew v-model="ui.showCreateGenre" />

  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'

import { createFlowMixin } from '@/mixins'
import GenreNew from './components/GenreNew.vue'
import Log from '@/services/logger'

const KFlow = "resource:genre"

const FlowMixin = createFlowMixin({
  KFlow,
  dataName: 'genre',
  showCreate: 'showCreateGenre',
  getName: (e) => (e.name),
  editUrl: (e) => (`/resources/genres/${e.id}`),
  defaultSort: {
    sortField: 'name',
    sortOrder: 1,
  }
})

export default {
  mixins: [FlowMixin],
  components: {
    DataTable,
    Column,
    InputText,
    GenreNew
  },
  data: () => ({
    ui: {
      showCreateGenre: false
    }
  }),
  methods: {
    async getList () {
      const params = this.getLazyParams()
      this.loading = true
      try {
        const { data: list, total} = await this.$pxstream.resource.getGenres(params)
        this.totalRecords = total
        this.list = list
      } catch (e) {
        Log.Error(e)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$breadcrumb.setCurrent('Genres', [
      {label: 'Resources', disabled: true},
      {label: 'Genres', disabled: true}
    ])

    await this.getList()
  }
}
</script>
