<template>
  <div v-if="editorKey">
    <div class="flex flex-column flex-column-reverse md:flex-row">
      <div class="w-full fluid">

        <div class="field grid">
          <label for="name" :class="{'p-error': nameError, 'col-12 md:col-3 font-bold': true}">{{ $t('forms.name')}}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="name" type="text"
                :modelValue="name"
                v-debounce:300ms="(val) => (name = val)"
                :class="{'p-invalid': nameError}"
                :disabled="isLoading"
              />
              <small v-if="nameError" class="p-error">{{nameError}}</small>
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="contentTypes" class="col-12 md:col-3">{{ $t('forms.contentTypes')}}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <AutoComplete
                v-model="contentTypes"
                itemValue="id"
                :field="(item) => $t(`resources.contentTypes.${item.name}`)"
                multiple
                dropdown
                :suggestions="listingContentTypes"
                @complete="searchContentTypes($event)"
                class="w-full"
              />
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="isBlocked" class="col-12 md:col-3">{{ $t('forms.blocked')}}</label>
          <div class="col md:col-8">
            <InputSwitch v-model="isBlocked" id="isBlocked" />
          </div>
        </div>

      </div>
      <div class="mb-4 md:m-0">
        <div class="field grid center">
          <LanguageSelector class="" v-model="language" :onlyLanguages="availableLanguages" />
        </div>

        <div class="field grid center">
          <Button 
            label="Add language" 
            icon="pi pi-plus" 
            iconPos="right" 
            :disabled="!language"
            @click="showAddLangDialog = true"
          />
        </div>

        <DialogAddLang :languages="availableLanguages" @languageAdded="addLang" v-model="showAddLangDialog" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import AutoComplete from 'primevue/autocomplete'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import { mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getDirective as vueDebounce } from 'vue-debounce'

import { useFlowEditor } from '@/compositions'

import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import DialogAddLang from '../../../portalmanager/menus/components/generalInformation/components/DialogAddLang.vue'

export default {
  name: 'GenreEditor',
  components: {
    InputText,
    InputSwitch,
    AutoComplete,
    LanguageSelector,
    DialogAddLang
  },
  directives: {
   debounce: vueDebounce(3)
  },
  props: { editorKey: String },
  setup (props) {

    const listingContentTypes = ref([])
    const language = ref('2')
    const showAddLangDialog = ref(false)
    return {
      listingContentTypes,
      language,
      showAddLangDialog,
      v$: useVuelidate(),
      ...useFlowEditor(props.editorKey)
    }
  },
  computed: {
    ...mapGetters('resource', ['languageById', 'contentFormats']),
    availableLanguages () {
      const languages = Object.keys(this.fieldGet(`i18n`))
      !languages.includes('2') ? languages.push('2') : ''
      return languages
    },
    name: {
      get () { 
        if (!this.fieldGet(`i18n.${this.language}`) && this.language !== '2') {
          return ''
        }
        let itemToSelect = this.language !== '2' ? `i18n.${this.language}.name` : 'name'
        return this.fieldGet(itemToSelect)
      
      },
      set (value) { 
        if (!this.fieldGet(`i18n.${this.language}`) && this.language !== '2') {
          this.fieldObjSet({field: 'i18n', key: this.language, value: { name: value}})
        } else {
          let itemToChange = this.language !== '2' ? `i18n.${this.language}.name` : 'name'
          this.fieldSet({field: itemToChange, value })
        }
        
      }
    },
    contentTypes: {
      get () { return this.fieldGet('contentTypes') },
      set (value) { this.fieldSet({field: 'contentTypes', value})}
    },
    isBlocked: {
      get () { return this.fieldGet('isBlocked') },
      set (value) { this.fieldSet({field: 'isBlocked', value})}
    },
    nameError () {
      if (this.v$.name.required.$invalid) return 'Name is required'
      return ''
    },
    isLoading () {
      return this.isSaving
    },
    contentTypesOptions () {
      return this.contentFormats.reduce((types, format) => ([
          ...types,
          ...format.contentTypes.map((t) => ({
            ...t,
            format: {
              id: format.id,
              name: format.name
            }
          }))
        ]), [])
    }
  },
  methods: {
    addLang (event) {
      this.language = event.id
      this.fieldObjSet({field: 'i18n', key: this.language, value: { name: ''}})
    },
    async searchContentTypes ({query}) {
      const isNotSelected = (v) => {
        if (this.contentTypes) {
          return !(this.contentTypes.some((t) => t.name === v.name))
        }
        return true
      }
      this.listingContentTypes = this.contentTypesOptions.filter(isNotSelected).filter((v) => v.name.includes(query))
    }
  },
  watch: {
    'doc.name': function (newName) {
      const docId = this.doc.id
      let title = newName || 'Untitled'
      this.$breadcrumb.setCurrent(title, [
        {label: 'Resources', disabled: true},
        {label: 'Genres', disabled: false, to: '/resources/genres'},
        {label: title, disabled: true}
      ], {caption: '', badge: docId === 'new' ? 'Draft' : ''})
    },
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    }
  },
  validations: {
    name: {
      required
    }
  }
}
</script>
